<template>
    <div class="config-window">
        <div v-if="message" class="error-overlay">
            <div class="error-container">
                <div class="error-message" v-html="message"></div>
                <div style="text-align: center;">
                    <button @click="message = ''">OK</button>
                </div>
            </div>
        </div>
        <div class="config-load">
            <div class="builds-header config-tabs">
                <div @click="activeTab='user'" class="config-tabs-title noselect" :class="{'config-tabs-active': activeTab==='user'}">Your configs</div>
                <div @click="activeTab='hash'" class="config-tabs-title noselect" :class="{'config-tabs-active': activeTab==='hash'}">Import config from ID</div>
                <div @click="activeTab='share'" class="config-tabs-title noselect" :class="{'config-tabs-active': activeTab==='share'}">Share config</div>
            </div>

            <div v-if="activeTab === 'user'" class="config-tab-contents">
                <div v-if="!showNew">
                    Select config:
                    <select v-model="selectID" @change="fetchConfig(selectID, false)" style="width: 200px;" :disabled="disableLoad || isModified">
                        <option v-for="c in configList" :value="c.id" :key="c.id">
                            <span v-if="c.active">*</span> {{ c.title }}
                        </option>
                    </select>
                    <button v-if="configID" @click="showNew = !showNew; copyID = configID" :disabled="disableEdit || isModified">New</button>
                    <button v-if="configID" @click="deleteConfig(configID)" :disabled="disableEdit || isModified">Delete</button>
                    <button @click="makeActive()" :disabled="!configID || configActive || disableEdit || isModified">Make Default</button>
                </div>
                <div v-else>
                    Config name:
                    <input v-model="newTitle" type="text" style="width: 200px;">
                    Copy from:
                    <select v-model="copyID" style="width: 200px;">
                        <option value="default" key="default">default</option>
                        <option v-for="c in configList" :value="c.id" :key="c.id">
                            {{ c.title }}
                        </option>
                    </select>
                    <button :disabled="disableLoad || isModified || newTitle.length === 0" @click="fetchConfig(copyID, true)" style="margin-left: 10px;">Create</button>
                    <button @click="showNew = false" style="margin-left: 10px;">Cancel</button>
                </div>
            </div>
            <div v-else-if="activeTab === 'hash'" class="config-tab-contents">
                Config hash:
                <input v-model="loadID" type="text" style="width: 260px;">
                New name:
                <input v-model="newTitle" type="text" style="width: 200px;">
                <button @click="fetchConfig(loadID, true);" :disabled="disableLoad || isModified || loadID.length !== 36 || !newTitle">Import</button>
            </div>
            <div v-else class="config-tab-contents">
                Branch:
                <select v-model="shareBranch" style="width: 200px;">
                    <option v-for="c in appData.sortedBranches" :value="c.clean_name" :key="c.id" :disabled="!c.success">
                        {{ c.name }}
                    </option>
                </select>
                <button :disabled="isModified" @click="copyShare()">Copy Preview Link</button>
                <button :disabled="isModified" @click="openConfig()">Open config merged with branch</button>
            </div>
        </div>
        <div class="config-container">
            <div class="config-tools">
                <input type="text" v-model="configTitle" :disabled="disableEdit">
                <button @click="sendConfig()" :disabled="disableEdit || (!isModified && this.configListHasID(this.configID))">Save</button>
                <button @click="revertConfig()" style="margin-right: 10px;" :disabled="disableEdit || !isModified">Revert</button>
                <span />
                <div v-if="!isModified && configID">Config ID: <a :href="masterURL" target="_blank" style="color: blue;">{{ configID }}</a></div>
            </div>
            <div class="config-text" @keydown="handleKeyDown">
                <v-jsoneditor
                    v-model="configData"
                    ref="jsoneditor"
                    :plus="false"
                    :options="{
                        mode: 'code',
                        mainMenuBar: false,
                        onChangeText: this.editorChanged,
                        indentation: 4
                    }"
                    :disabled="disableEdit"
                    class="config-text-jsoneditor"
                />
            </div>
        </div>
    </div>
</template>

<script>
import appData from "@/libs/appData";
import VJsoneditor from "v-jsoneditor/src/index";

/* eslint-disable no-multiple-empty-lines */
export default {
    name: 'ConfigEdit',
    components: {
        VJsoneditor,
    },
    data() {
        return {
            activeTab: 'user',
            configList: [],

            configID: '',
            configTitle: '',
            configActive: false,
            configData: '',

            showNew: false,

            loadedTitle: '',
            loadedData: '',

            selectID: '',
            newTitle: '',
            copyID: 'default',
            loadID: '',

            shareBranch: 'master',

            // used to display errors/info
            message: '',

            disableLoad: false,
            disableEdit: false,
            editorModified: false,
        };
    },
    computed: {
        isModified() {
            return this.editorModified || this.loadedTitle !== this.configTitle;
        },
        appData() {
            return appData;
        },
        baseURL() {
            return '/' + appData.user + '/' + appData.repo + '/' + this.shareBranch;
        },
        shareURL() {
            return this.baseURL + '/?config=' + encodeURIComponent(this.configID);
        },
        shareConfig() {
            return this.baseURL + '/static/config_' + encodeURIComponent(this.configID) + '.json';
        },
        masterURL() {
            return '/kiwiirc/kiwiirc/master/?config=' + encodeURIComponent(this.configID);
        },
        editor() {
            return this.$refs.jsoneditor.editor;
        },
    },
    created: function() {
        if (appData.user !== 'kiwiirc') {
            appData.user = 'kiwiirc';
        }
        if (appData.repo !== 'kiwiirc') {
            appData.repo = 'kiwiirc';
        }
        this.fetchConfigList();
    },
    methods: {
        handleKeyDown(ev) {
            if (ev.code !== 'KeyS' || !ev.ctrlKey) {
                return;
            }

            event.preventDefault();

            if (!(this.disableEdit || (!this.isModified && this.configListHasID(this.configID)))) {
                this.sendConfig();
            }
        },
        fetchConfigList() {
            this.disableLoad = true;
            this.disableEdit = true;
            fetch(appData.apiURL + '/configs/list')
                .then(r => {
                    if (r.ok) {
                        return r.json();
                    }
                    throw new Error(r.status + ' ' + r.statusText);
                })
                .then(j => {
                    this.handleConfigList(j);
                })
                .catch(r => {
                    this.message = 'Caught an exception trying to fetch the config list<br/>' + r;
                    this.disableLoad = false;
                    this.disableEdit = false;
                });
        },
        fetchConfig(id, isNew) {
            this.disableLoad = true;
            this.disableEdit = true;
            this.configID = '';
            this.configData = '';
            this.loadedData = '';
            this.configTitle = '';
            this.loadedTitle = '';
            this.configActive = true;
            fetch(appData.apiURL + '/configs/get?id=' + id)
                .then(r => {
                    if (r.ok) {
                        return r.json();
                    }
                    throw new Error(r.status + ' ' + r.statusText);
                })
                .then(j => {
                    let c = j.config;
                    if (!isNew && c.id !== this.selectID && c.id !== this.loadID) {
                        return;
                    }

                    this.loadID = '';
                    this.configID = c.id;
                    try {
                        this.configData = JSON.parse(c.data);
                    } catch (e) {
                        console.log(
                            'This should not have happened, please notify dev (fetchConfig)',
                            e.message ? e.message : "Unknown Parse Error"
                        );
                    }
                    this.loadedData = c.data;

                    if (isNew) {
                        this.configTitle = this.newTitle;
                        this.loadedTitle = this.newTitle;
                        this.configActive = (this.configList.length === 0);
                        this.configID = '';
                        this.newTitle = '';
                    } else if (c.id === '') {
                        this.configTitle = 'New Config';
                        this.loadedTitle = this.configTitle;
                    } else {
                        this.configTitle = c.title;
                        this.loadedTitle = c.title;
                        this.configActive = c.active;
                    }

                    this.disableLoad = false;
                    this.disableEdit = false;

                    if (isNew) {
                        this.showNew = false;
                        this.sendConfig();
                    }
                })
                .catch(r => {
                    if (r.message === '404 Not Found') {
                        this.message = 'Could not find a config matching id: ' + id;
                    } else {
                        this.message = 'Caught an exception trying to fetch the config<br/>' + r;
                    }
                    this.disableLoad = false;
                    this.disableEdit = false;
                });
        },
        sendConfig() {
            this.disableLoad = true;
            this.disableEdit = true;
            let outData = {};
            try {
                outData = JSON.stringify(this.editor.get());
            } catch (e) {
                this.message = e.message
                    ? e.message.replace(/\r?\n/g, "<br/>")
                    : "Unknown Parse Error";
                this.disableLoad = false;
                this.disableEdit = false;
                return;
            }

            fetch(appData.apiURL + '/configs/save', {
                method: 'POST',
                headers: { 'Content-Type': 'text/plain' },
                body: JSON.stringify({'id': this.configID, 'title': this.configTitle, 'data': outData, 'active': this.configActive}),
            })
                .then(r => {
                    if (r.ok) {
                        return r.json();
                    }
                    throw new Error(r.status + ' ' + r.statusText);
                })
                .then(j => {
                    this.handleConfigList(j);
                    let c = j.config;

                    this.configID = c.id;
                    this.selectID = c.id;
                    try {
                        this.configData = JSON.parse(c.data);
                    } catch (e) {
                        console.log(
                            "This should not have happened, please notify dev (sendConfig)",
                            e.message ? e.message : "Unknown Parse Error"
                        );
                    }
                    this.loadedData = c.data;
                    this.loadedTitle = c.title;
                    this.configActive = c.active;
                    this.disableLoad = false;
                    this.disableEdit = false;
                    this.editorChanged(this.loadedData);
                })
                .catch(r => {
                    this.message = 'Caught an exception trying to save config<br/>' + r;
                    this.disableLoad = false;
                    this.disableEdit = false;
                });
        },
        deleteConfig(id) {
            this.disableLoad = true;
            this.disableEdit = true;
            if (!window.confirm('Are you sure you would like to delete "' + this.configTitle + '"')) {
                return;
            }

            fetch(appData.apiURL + '/configs/delete?id=' + id)
                .then(r => {
                    if (r.ok) {
                        return r.json();
                    }
                    throw new Error(r.status + ' ' + r.statusText);
                })
                .then(j => {
                    this.configID = '';
                    this.configData = '';
                    this.loadedData = '';
                    this.configTitle = '';
                    this.loadedTitle = '';
                    this.configActive = true;
                    this.disableLoad = false;
                    this.disableEdit = false;
                    this.handleConfigList(j);
                })
                .catch(r => {
                    this.message = 'Caught an exception trying to delete config<br/>' + r;
                    this.disableLoad = false;
                    this.disableEdit = false;
                });
        },
        handleConfigList(response) {
            if ('config_list' in response) {
                this.configList = response.config_list;

                // if there are no configs show a default one
                if (this.configList.length === 0) {
                    return;
                }

                if (this.configID || this.configData || this.configTitle) {
                    return;
                }

                // if no config selected find our active config
                for (let i in this.configList) {
                    if (this.configList[i].active) {
                        this.selectID = this.configList[i].id;
                        this.fetchConfig(this.selectID);
                        return;
                    }
                }
            } else {
                this.configID = '';
                this.configTitle = 'New Config';
                this.configActive = true;
                this.fetchConfig('default');
            }
        },
        makeActive() {
            this.configActive = !this.configActive;
            this.sendConfig();
        },
        revertConfig() {
            this.configTitle = this.loadedTitle;
            try {
                this.configData = JSON.parse(this.loadedData);
            } catch (e) {
                console.log(
                    "This should not have happened, please notify dev (revertConfig)",
                    e.message ? e.message : "Unknown Parse Error"
                );
            }
            this.editorChanged(this.loadedData);
        },
        editorChanged(ev) {
            this.editorModified =
        JSON.stringify(ev) !== JSON.stringify(this.loadedData);
        },
        openConfig() {
            window.open(this.shareConfig, '_blank');
        },
        copyShare() {
            let el = document.createElement('input');
            el.value = new URL(this.shareURL, window.location.href);
            el.setAttribute('readonly', '');
            el.style = {position: 'absolute', left: '-9999px'};
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        },
        configListHasID(id) {
            for (let i in this.configList) {
                let config = this.configList[i];
                if (config.id === id) {
                    return true;
                }
            }
            return false;
        },
    },
};
</script>
<style>
.config-window {
  width: 60%;
  margin: auto;
  padding: 10px;
  height: 100%;
  box-sizing: border-box;
}
.config-load {
  border: 1px solid black;
  box-sizing: border-box;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 75px;
}
.config-tab-contents {
  padding: 10px;
}
.config-tab-contents button {
  margin-left: 10px;
}
.config-tabs-active {
  background-color: rgba(255, 255, 255, 0.15);
}
.config-tabs {
  box-sizing: border-box;
  height: auto;
}
.config-tabs > div {
  padding: 6px 12px;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;
}
.config-container {
  border: 1px solid black;
}

.config-tools {
  align-items: center;
  padding: 2px 10px;
  display: flex;
  border-bottom: 1px solid black;
}
.config-tools > span {
  flex-grow: 1;
}
.config-tools > button {
  margin-left: 10px;
}
.config-tools > div {
  display: inline-block;
}
.config-tools > input {
  width: 200px;
}
.config-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 80%;
  width: 100%;
}
.config-text {
  flex-grow: 2;
  width: 100%;
}
.config-text-jsoneditor {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  resize: none;
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.error-overlay {
  position: absolute;
  display: flex;
  background: rgba(0, 0, 0, 0.4);
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.error-container {
  background: #fff;
  padding: 20px;
  border: 4px solid red;
}
.error-message {
  width: 400px;
  height: 100px;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
.jsoneditor {
  border: none !important;
}
</style>
